import axiosClient from '@/helper/call-center';

export const authApi = {
  customer_sign(payload: {
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    phone_number: string;
    code_confirm: string;
    address: { city: string; country: string; state: string; zip: string };
  }) {
    return axiosClient.post('/customer/register', payload);
  },
  exist(email: string) {
    return axiosClient.get(`/customer/mail-exist/${email}`);
  },
  exitsEmailEmployee(email: string) {
    return axiosClient.get(`/employee/mail-exist/${email}`);
  },
  existPhone(phone: string) {
    return axiosClient.get(`/customer/phone-exist/${phone}`);
  },
  existPhoneEmployee(phone: string) {
    return axiosClient.get(`/employee/phone-exist/${phone}`);
  },
  login(payload: any) {
    return axiosClient.post('/auth/login', payload);
  },
  confirm_mail(payload: { email: string }) {
    return axiosClient.post('/auth/confirm-mail', payload);
  },
  confirm_phone(payload: { phone: string }) {
    return axiosClient.post('/auth/confirm-phone', payload);
  },
  profile() {
    return axiosClient.get('/auth/profile');
  },
  logout(token: string) {
    return axiosClient.delete(`/auth/logout/${token}`);
  },
  retrieve_password(data: string) {
    return axiosClient.post(`/customer/retrieve-password`, data);
  },
  createCustomerByEmployer(payload: {
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    phone_number: string;
    zip: string;
    city?: string;
    country?: string;
    state?: string;
  }) {
    return axiosClient.post('/auth/add-client-by-employer', payload);
  },
  updateCustomerByEmployer(
    id: string,
    payload: {
      firstName: string;
      lastName: string;
      password?: string;
      email: string;
      phone_number: string;
      zip: string;
      city?: string;
      country?: string;
      state?: string;
    },
  ) {
    return axiosClient.put('/customer/' + id, payload);
  },
  createUser(payload: {
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    phone_number: string;
    gender: string;
    roleId: string;
    storeBranches: string[];
  }) {
    return axiosClient.post('/auth/create-user', payload);
  },
  updateUser(
    id: string,
    payload: {
      firstName: string;
      lastName: string;
      password?: string;
      email: string;
      phone: string;
      gender: string;
      roleId: string;
      storeBranches: string;
    },
  ) {
    return axiosClient.put(`/employee/${id}`, payload);
  },
};
